<template>
  <tablebox v-loading="loading" :element-loading-text="config.loading.text"
    :element-loading-spinner="config.loading.spinner" :element-loading-background="config.loading.background"
    class="dp-f">
    <choose class="mr-20" @Emit="butemit" :Data="state.data"></choose>
    <my v-if="state.butIndex == 0"></my>
    <!-- <reviewed v-if="state.butIndex == 1" ref=reviewedRef  @updateNum="updateNum"></reviewed> -->
  </tablebox>
</template>
<script setup>
import { onMounted, reactive, ref, unref } from "vue";
import choose from "@/components/sidebar/choose.vue";
import my from "./components/my.vue"; //我的印章
import reviewed from "./components/reviewed.vue"; //待审核印章
import { httpToken } from '@/utils/request'
const loading = ref(false); //loading 显示
const reviewedRef = ref()
const state = reactive({
  butIndex: 0, //按钮选中下标
  data: [
    { title: '印章列表' },
    // { title: '待审核印章', count: 0 }
  ]
});
// 按钮返回
const butemit = (el) => {
  state.butIndex = el;
  // console.log('按钮返回',el)
};
// const getNumer = () => {
//   httpToken({
//     method: "post",
//     url: '/seal/selectRechargeRecordForInvoicable',
//   }).then(res => {
//     state.data[1].count = res.data
//   })
// }
const updateNum = () => {
  // getNumer()
}
onMounted(() => {
  // getNumer()

})
</script>
<style lang="scss" scoped >
@import "@/styles/general/element.scss"; //element 样式重置
@import "@/styles/general/element/table.scss"; //element 列表样式重置
@import "@/styles/general/element/pagination1.scss"; //element 分页样式重置
</style>